import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "./App.scss";
import { useSessionStorage } from "./hooks";

const monthNames: { [name: string]: string } = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December",
};

type DirectoryItem = {
  name: string;
  type: string;
  mtime: string;
};

// Add hook to set Authorization header.
axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("statsAuth");
  if (!token) return config;
  if (config.headers) {
    //config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const Login = ({ handleLogin }: { handleLogin: (token: string) => void }) => {
  const [state, setState] = useState<{ user: string; password: string }>({
    user: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const attemptLogin = useCallback(async () => {
    const token = btoa(`${state.user}:${state.password}`);

    try {
      const response = await axios.post("/access", null, {
        headers: { Authorization: `Bearer ${token}` },
      });
      handleLogin(token);
    } catch (error: unknown) {
      if (!axios.isAxiosError(error) || !error.response) return;
      setErrorMessage(
        "Login failed. Ensure you are using the correct FTP username and password."
      );
    }
  }, [state]);

  const canSubmit = state.user !== "" && state.password !== "";

  return (
    <>
      <main>
        <header>
          <img className="headerLogo" src="/images/logo-no.svg"></img>
          <div className="subTitle">
            <span className="orange">Webpage</span>{" "}
            <span className="blue">statistics</span>
          </div>
        </header>
        <div className="description">
          <p>
            Please log in below with your FTP credentials to access statistics.
          </p>
        </div>
        {errorMessage && <div className="errorBox">{errorMessage}</div>}
        <div className="login">
          <form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              attemptLogin();
            }}
          >
            <div>
              <label>Username</label>
              <input
                type="text"
                placeholder="Username"
                value={state.user}
                onChange={(e) => setState({ ...state, user: e.target.value })}
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                placeholder="Password"
                value={state.password}
                onChange={(e) =>
                  setState({ ...state, password: e.target.value })
                }
              />
              <button disabled={!canSubmit}>
                Log in
              </button>
            </div>
          </form>
        </div>
      </main>
      <footer>Domeneshop AS &middot; <a href="https://domainname.shop/faq?id=280&lang=en" target="_blank">Privacy</a></footer>
    </>
  );
};

const StatsView = ({ handleLogout }: { handleLogout: () => void }) => {
  const [isLoadingList, setLoadingList] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [domainList, setDomainList] = useState<DirectoryItem[]>([]);
  const [logFileList, setLogFileList] = useState<DirectoryItem[]>([]);
  const [isLogFileListVisible, setLogFileListVisible] = useState(false);
  const [domainDateList, setDomainDateList] = useState(
    new Map<string, DirectoryItem[]>()
  );

  useEffect(() => {
    async function loadData() {
      try {
        let result = await axios.get("/data/");
        setDomainList(result.data);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status > 299) {
            setErrorMessage(
              `Something went wrong while fetching statistics domains (${error.response.status}). Try again later.`
            );
          }
        } else {
          setErrorMessage(
            "An unknown error occurred while fetching statistics domains. Try again later."
          );
        }
      } finally {
        setLoadingList(false);
      }
    }

    async function loadLogFiles() {
      try {
        let result = await axios.get("/logfiles/");
        setLogFileList(result.data);
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 404) {
            return;
          }
          // Throw for everything else
          throw error;
        }
      }
    }

    loadData();

    // Get list of raw logfiles
    // Returns 404 if user does not have raw logfiles enabled
    loadLogFiles();
  }, []);

  const loadDomainName = async (domainName: string) => {
    let result = await axios.get<DirectoryItem[]>(`/data/${domainName}/`);
    setDomainDateList((prevState) => {
      return new Map<string, DirectoryItem[]>(prevState).set(
        domainName,
        result.data.sort((a, b) => {
          return b.name.localeCompare(a.name);
        })
      );
    });
  };

  const dirList = (list: DirectoryItem[]) =>
    list
      .filter((el) => el.type === "directory" && el.name !== "2a01")
      .sort((a, b) => {
        if (a.name === "all") {
          return -1;
        }
        return a.name.localeCompare(b.name);
      });

  const fileList = (list: DirectoryItem[]) =>
    list
      .filter((el) => el.type === "file")
      .sort((a, b) => {
        return b.name.localeCompare(a.name);
      });

  const list = dirList(domainList).map((el) => {
    let dateList;
    if (domainDateList.has(el.name)) {
      let ddl = domainDateList.get(el.name);
      if (ddl) {
        dateList = ddl.map((del) => {
          const dateTitle =
            del.name.substr(0, 4) + " " + monthNames[del.name.substr(4, 2)];
          return (
            <li>
              <a
                href={`/data/${el.name}/${del.name}/awstats.${el.name}.html`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {dateTitle}
              </a>
            </li>
          );
        });
        dateList = <ul>{dateList}</ul>;
      }
    }

    const title = el.name === "all" ? "All domains" : el.name;
    return (
      <li>
        <div className="domain" onClick={() => loadDomainName(el.name)}>
          {title}
        </div>
        {dateList}
      </li>
    );
  });

  const lfList = fileList(logFileList).map((el) => {
    return (
      <li>
        <a
          href={`/logfiles/${el.name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {el.name}
        </a>
      </li>
    );
  });

  const logFiles =
    lfList.length > 0 ? (
      <ul>
        <li>
          <div className="domain" onClick={() => setLogFileListVisible(true)}>
            Raw Log Files
          </div>
          {isLogFileListVisible && <ul>{lfList}</ul>}
        </li>
      </ul>
    ) : null;

  return (
    <>
      <main>
        <header>
          <button className="logoutButton" onClick={handleLogout}>
            Log out
          </button>
          <img className="headerLogo" src="/images/logo-no.svg"></img>
          <div className="subTitle">
            <span className="orange">Webpage</span>{" "}
            <span className="blue">statistics</span>
          </div>
        </header>
        <div className="description">
          <p>Click a domain name below, and then a month to view statistics.</p>
          <p>
            <strong>Note:</strong> Please be aware that statistics will only be
            generated if you have visited this page in the last 12 months. Less frequent visits <i>will</i> cause gaps in data.
          </p>
        </div>
        <div className="tree">
          {errorMessage && <div className="errorBox">{errorMessage}</div>}
          {!isLoadingList ? (
            list.length > 0 ? (
              <ul>{list}</ul>
            ) : (
              errorMessage === null && (
                <div className="infoBox">
                  No domains with statistics found. If this is your first visit,
                  please allow a few hours for statistics to generate.
                </div>
              )
            )
          ) : (
            <div className="loader">Loading data...</div>
          )}
          {logFiles && (
            <>
              <hr />
              {logFiles}
            </>
          )}
        </div>
      </main>
      <footer>Domeneshop AS</footer>
    </>
  );
};

function App() {
  //const [token, setToken, unsetToken] = useSessionStorage("statsAuth");
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);

  useEffect(() => {
    // Test if user is logged in (e.g. a valid cookie is set) by attempting to post to /access
    const testLogin = async () => {
      try {
        const response = await axios.post("/access", null);
        if (response.status === 204) setLoggedIn(true);
      } catch (error: unknown) {
        setLoggedIn(false);
      }
    };

    testLogin();
  }, []);

  const logout = useCallback(async () => {
    const result = await axios.get("/logout");
    //unsetToken();
    setLoggedIn(false);
  }, []);

  switch (loggedIn) {
    case null:
      return <div className="loader">Loading data...</div>;
    case true:
      return <StatsView handleLogout={logout} />;
    default:
      return <Login handleLogin={() => setLoggedIn(true)} />;
  }
}

export default App;
